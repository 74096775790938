
import React from 'react'
import photo_data from 'src/_data/beginning.json'
import { sortAlbums } from 'src/utils.js'
import Carousel from 'nuka-carousel';
import Img from 'gatsby-image'
import Container from '@material-ui/core/Container'
import tw, { styled } from 'twin.macro'
import { createGlobalStyle } from "styled-components"
import { Header } from 'src/components/Header'
import { Footer } from 'src/components/Footer'

const GlobalStyle = createGlobalStyle`
.prev-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.next-btn{
  display: none;
  @media screen and (min-width: 800px){
    display: block;
  }
}
.italic{
font-style:italic;
a{text-decoration: underlined;
&:hover{font-weight: bold;}}
}
body{margin:0}
p{
  font-family: 'Cormorant Garamond', serif;
}
.slider-control-bottomcenter{
  bottom: -3rem!important;
}
.gatsby-image-wrapper{
  display: block!important;
  margin: auto;
}
.text-center{
  text-align: center;
}
.page-content p{
	font-family: 'Cormorant Garamond', serif;
	font-size: 120%;
	text-align: justify;
    margin: 1em 1.5em 1em 1.5em;
}

.page-content{
  margin-top: -8rem!important;
	position: relative;
	background-color: #fff;
	width: 90%;
	max-width: 800px;
	margin: auto;
	z-index: 2;
	box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	padding: 2em 0 5em 0;
}

@media (max-width: 400px){
	.page-content p{
	  font-size: 100%;
}
}
@media (max-width: 900px) {
	.page-content p{
  	font-size: 110%;
}
}

@media (min-width: 901px) { 
  .page-content p{
	  font-size: 120%;
  }
}
.page-title-wrapper {
  color: red;
  h2{
    text-align: center;
	font-family: 'Cinzel', serif;
	font-size: 2em;
	font-weight: normal;
	color: #8d929c;
    margin: 0.5em 0 1.5em 0;
}}
.page-content .text-center{
  text-align: center;
}
.slider-slide {
  :focus {outline: none!important;}
}


.bottom-page-image-wrapper{
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
	width: 100%;
	/*height: 100%;*/
}

.bottom-page-image{
	align-self: flex-end;
	width: 100%;
    background: linear-gradient(ghostwhite 10%, rgba(0, 0, 0, 0) 35%);
}

.bottom-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}

.top-page-image-wrapper{
	position: absolute;
	z-index: -5;
	top: 0;
	width: 100%;
}

.top-page-image{
	width: 100%;
    background: linear-gradient(rgba(248, 0, 0, 0) 42%, ghostwhite 73%, ghostwhite);
}

.top-page-image img{
	position: relative;
	width: 100%;
	z-index: -1;
}
`


const CarouselWrapper = styled.div`
width: 100%;
  
@media screen and (min-width: 800px){
  width: 750px;
}
  margin: auto;
  margin-bottom: 3rem;
`



const SlideWrapper = styled.div`
 ${tw`mb-8`}
`

const ImageWrapper = styled.div`
  height: calc(100% - 3rem);
`
const componentName = styled(Img)`
     picture > img {
    height: auto !important;
    }
    `
const Beginning = ({ location, data }) => {

  const nodes = data.allFile.nodes

  const sortedAlbums = sortAlbums(nodes, photo_data)
  const bottom = nodes.filter(n => n.relativePath.match('0007'))[0].childImageSharp.fluid
  const top = nodes.filter(n => n.relativePath.match('0013'))[0].childImageSharp.fluid
  return (
    <>
      <GlobalStyle />

      <Container>
        <Header location={location} />
        <div className='page-content'>
          <div className='page-title-wrapper centered-text'>
            <h2>The beginning</h2>
          </div>
          <p>The early history is not clear, but by 1700 Fertot was an oratory, maintained by the owners of le Marais. Razed to the ground after 1790, the house was rebuilt in a different style on the original foundations some time during the 1840s by the comte de Moncorps, who also commissioned a local but highly regarded paysagiste, Paul de Lavenne, comte de Choulot, to re-work the landscaping.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 1).length > 1 ? true : true}heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 1).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 1).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>
          <p>Paul de Lavenne, comte de Choulot, was born on the 31st of January 1794 in Nevers, but spent most of his youth as an exile in London. He introduced the concept of the <span class='italic'>parc agricole</span> to France in the 1840s and during his career designed about 280 parks in Europe, mostly in France. By 1858 he was designing the garden-city of le Vésinet, to the west of Paris. More information about the comte de Choulot can be found in the book <span class='italic'><a href='http://www.caue58.com/2018/04/17/paul-de-lavenne-comte-de-choulot-paysagiste-1794-1864/' target="_blank" >Paul de Lavenne comte de Choulot Paysagiste (1794 – 1864)</a></span> editions du CAUE de la Niévre.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 2).length > 1 ? true : true}heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 2).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 2).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>


          <p>The present château was rebuilt in the 1840s on the foundations of the previous house, which is thought to have been an oratory or a monastery. Some of the farm buildings date from the 17th century.</p>
          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 3).length > 1 ? true : true}heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 3).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 3).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>

          <p><span class='italic'>L'art des Jardins, ou etudes theoriques pratiques sur l'arrangement exterieur des habitations;</span> was an essay published in 1863 authored by the comte de Choulot mentioning the family de Moncorps as owners of the chateau de Fertot at the time.</p>


          <CarouselWrapper>
            <Carousel  disableEdgeSwiping={sortedAlbums.filter(a => a.album === 4).length > 1 ? true : true}heightMode="current" defaultControlsConfig={{
              nextButtonText: '>',
              nextButtonClassName: "next-btn",
              prevButtonText: '<',
              prevButtonClassName: "prev-btn",
              nextButtonStyle: {
                display
                  : sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none'
              },
              prevButtonStyle: { display: sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none' },
              pagingDotsStyle: { display: sortedAlbums.filter(a => a.album === 4).length > 1 ? '' : 'none' }
            }}>
              {sortedAlbums.filter(a => a.album === 4).map(a => <SlideWrapper>
                <ImageWrapper>
                  <Img fluid={a.data[0].childImageSharp.fluid} imgStyle={{ objectFit: 'contain' }} />
                </ImageWrapper>
                <p className="text-center">{a.caption}</p>
              </SlideWrapper>)}
            </Carousel>
          </CarouselWrapper>
        </div>
      </Container>
      <div className='bottom-page-image-wrapper'>
        <div className='bottom-page-image'>
          <Img fluid={bottom}
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <div className='top-page-image-wrapper'>
        <div className='top-page-image'>
          <Img fluid={top}
            imgStyle={{ objectFit: 'cover' }}
          />
        </div>
      </div>
      <Footer />
    </>
  )
}

export const data = graphql`
  query beginning {
          allFile(filter: {relativeDirectory: {eq: "history/beginning"}}) {
          nodes {
          id
        childImageSharp {
          fixed(width: 600, quality: 100) {
          ...GatsbyImageSharpFixed
        }
          fluid(maxWidth: 1600, quality: 80) {
          aspectRatio
          ...GatsbyImageSharpFluid
        }
        }
        relativePath
      }
    }
  }
  `

export default Beginning
